.steps-content {
  display: flex;
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  background-color: #fafafa;
  min-height: 300px;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.steps-action {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  margin-top: 24px;
}

.form-steps-dialog {
  margin: auto;
  padding: 16px;
  vertical-align: middle;
  max-width: 100%;
  height: 100%;
}
