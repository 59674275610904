.ant-dropdown-menu {
  width: auto;
  border-radius: 4px;
}

.ant-space + .ant-space-item {
  height: 48px;
}

#user-profile-button {
  height: 100%;
  padding: 8px;
  padding-bottom: 8px;
}

#user-profile-button:hover {
  background-color: rgba(173, 216, 230, 0.148);
}

/* .btn-link {
  margin: 0;
  padding: 0;
}

.btn-link:hover {
  background: transparent;
  text-align: left;
} */
