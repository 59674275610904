#components-layout-demo-side {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.ant-layout-sider {
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.12);
  z-index: 3;
}

.logo {
  margin: auto;
  width: 65%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.ant-layout-header {
  justify-content: flex-end;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  height: 48px;
  z-index: 2;
}

.site-page-header {
  z-index: 1;
  width: 100%;
}

/* .ant-space + .ant-space-item {
  height: 48px;
} */

/* .header-content .ant-col {
  display: inline-flex;
  flex-direction: column;
  padding-right: 24px;
  margin: auto;
  align-items: flex-end;
  height: 48px;
  justify-content: space-around;
} */

div.header-controls {
  height: 48px;
}

.site-layout .site-layout-background {
  background: #fff;
  box-shadow: 0 1px 10px rgba(0, 21, 41, 0.08);
}

.ant-menu-inline-collapsed {
  width: 48px;
}

.ant-menu-inline > .ant-menu-submenu {
  margin-left: 0px;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  width: 48px;
}

.main-content-bg {
  border-radius: 4px;
}

.card-box-shadow {
  box-shadow: 0 1px 1px rgba(0, 21, 41, 0.08);
}

/* .custom-report-card .ant-card-body {
  min-height: 250px;
} */

.ant-notification-notice {
  border-radius: 4px;
  /* box-shadow: 0 0px 20px 0 rgba(0, 21, 41, 0.12); */
}
.ant-notification-topRight {
  margin-right: 16px;
}

.ant-message-notice-content {
  border-radius: 4px;
}

.ant-modal-content,
.ant-modal-content .ant-modal-header {
  border-radius: 4px;
}

/* .ant-typography-edit-content {
  background-color: pink;
  border-color: pink;
  font-size: 24px;
  width: 300px;
  height: 1.6em;
} */
.email-templates-list {
  overflow: auto;
  padding: 8px 24px;
  min-height: 350px;
  width: 100%;
}
